.scores {
	width: 250px;
	height: 250px;
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.scores-title {
	text-align: center;
	font-weight: bold;
	height: 30px;
}

.score {
	height: 20px;
	display: flex;
	width: 100%;
}

.score-position {
	width: 20px;
}

.score-surname {
	text-transform: uppercase;
}

.score-score {
	text-align: right;
	flex:1;
}
