form {
	font-size: 1.2em;
}

form span {
	font-size: 1em;
}

.form-control {
	padding: 5px;
	width: 250px;
	display: flex;
	justify-content: space-between;
}

label {
	font-size: 1em;
}

label span {
	color: red;
}

button {
	padding-top: 5px;
	font-family: mohave,sans-serif !important;
	width: 100%;
}

.error {
	width: 100%;
	padding: 5px;
	text-align: center;
	color: red;
	font-size: 0.7em;
}
